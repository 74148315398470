import React from 'react';
import history from 'history/browser';
import logo from './logo.png';
import './App.css';
import { useState, useEffect } from 'react';
import { throttle } from 'throttle-debounce';

function App() {
	const [search, setSearch] = useState('');
	const [searchResults, setSearchResults] = useState([]);
	const [showSpinner, setShowSpinner] = useState(false);

	const [errorMessages, setErrorMessages] = useState<string[]>([]);
	const [noResults, setNoResults] = useState(false);

	useEffect(() => {
		const params = new URLSearchParams(window.location.search);
		const query = params.get('query');
		if (query) {
			setSearch(query);
			debouncedSearch(query);
		}
	}, []);

	const debouncedSearch = throttle(1000, (query: string) => {
		doSearchWithState(query);
	});

	const doSearchWithState = (query: string) => {
		if (!query) {
			history.push({ pathname: '/' });
			setSearchResults([]);
			setNoResults(false);
			return;
		}

		doSearch(query);
		history.push({ pathname: '/', search: `?query=${encodeURIComponent(query)}` });
	};

	const doSearch = (query: string) => {
		setErrorMessages([]);

		if (!query) {
			setSearchResults([]);
			setNoResults(false);
			return;
		}

		const locale = navigator.language.substring(0, 2);

		setShowSpinner(true);
		fetch(`https://search.api.wirereader.app/?query=${query}&locale=${locale}`)
			.then((response) => {
				if (!response.ok) {
					switch (response.status) {
						case 429:
							setErrorMessages(['Too many requests. Please try again later.']);
							break;
						default:
							setErrorMessages(['An unknown error occurred. Please try again later.']);
							break;
					}
				}

				return response.json();
			})
			.then((body) => {
				if (!body || !body.data || !body.data.length) {
					setSearchResults([]);
					setNoResults(true);
					return;
				}

				setNoResults(false);
				setSearchResults(body.data);
			})
			.finally(() => {
				setShowSpinner(false);
			});
	};

	const submitSearch = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		debouncedSearch(search);
	};

	return (
		<div className={"App " + (searchResults.length > 0 ? 'hasResults' : 'hasNoResults')}>
			<div className="search">
				<form className="searchform" onSubmit={submitSearch}>
					<img src={logo} className="logo" alt="Wire Reader" />
					<input type="text" className="search-input" placeholder="Search" value={search} onChange={(e) => setSearch(e.target.value)} />
					<button className="search-button">Search</button>
					<span className="loader-wrapper">
						<span className={"loader " + (showSpinner && 'show')}></span>
					</span>
					{noResults && <p className="no-results">No results found.</p>}
					{errorMessages.length > 0 && (
						<div className="error-messages">
							{errorMessages.map((message, key) => (
								<p key={key}>{message}</p>
							))}
						</div>
					)}
				</form>
			</div>
			<div className="results">
				<ol>
					{searchResults.map((result: any) => (
						<li key={result.id}>
							<a href={result.url}>
								<div className="favicon">
									{result.icon && (
										<img src={result.icon} />
									) || (
										<span className="no-favicon"></span>
									)}
								</div>
								{result.name}
							</a>
							{result.description?.length > 0 &&
								<p className="description">{result.description}</p>
							}
							<p className="url">{result.originalUrl}</p>
						</li>
					))}
				</ol>
			</div>
			<div className="powered-by">
				<p>
					<a href="https://wirereader.app">
						<img src={logo} className="smalllogo" alt="Wire Reader" />
						Wire RSS Reader
					</a>
				</p>
			</div>
		</div>
	);
}

export default App;
